import React from 'react';

import RichText from 'components/atoms/rich-text';

import {
  StyledContainer,
  StyledWrapper,
  StyledWrapperText,
} from './personal-detail-description.styles';

export type PersonalDetailDescriptionType = { personalDescription: string };

const PersonalDetailDescription = ({ personalDescription }: PersonalDetailDescriptionType) => (
  <StyledWrapper>
    <StyledContainer>
      <StyledWrapperText>
        <RichText html={personalDescription} />
      </StyledWrapperText>
    </StyledContainer>
  </StyledWrapper>
);

export default PersonalDetailDescription;
