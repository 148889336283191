import styled from 'styled-components';
import { breakpoint } from 'theme';

import DotsGrid from 'components/molecules/dots-grid';
import { Button } from 'components/atoms/button';
import Container from 'components/atoms/container';
import Icon from 'components/atoms/icon';
import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div``;

export const StyledContainerBreadcrumb = styled(Container)`
  padding-top: ${({ theme }) => theme.gap.md};
  max-width: ${({ theme }) => theme.wrapper};
  text-transform: capitalize;

  & .breadcrumb__separator {
    color: ${({ theme }) => theme.colors.gray40};
  }

  & .breadcrumb__list__item a {
    color: ${({ theme }) => theme.colors.gray100};
  }

  & .breadcrumb__list__item a[aria-current='page'] {
    color: ${({ theme }) => theme.colors.gray60};
  }
`;

export const StyledPositionText = styled(Typography)`
  padding-block: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.md} ${({ theme }) => theme.gap.sm};
  }
`;

export const StyledWrapperContent = styled(Container)`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.wrapper};
  position: relative;
  padding-block: ${({ theme }) => theme.gap.md} 0;

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.md};
    flex-direction: row;
  }
`;

export const StyledDotsFirstMobile = styled(DotsGrid)`
  bottom: ${({ theme }) => theme.gap.sm};
  right: 37px;
`;

export const StyledDotsSecondMobile = styled(DotsGrid)`
  bottom: ${({ theme }) => theme.gap.sm};
  right: ${({ theme }) => theme.gap.sm};
`;

export const StyledDots = styled(DotsGrid)`
  ${breakpoint.md} {
    top: -${({ theme }) => theme.gap.md};
    left: -80px;
    z-index: -1;
  }
`;

export const StyledWrapperImage = styled.div`
  position: relative;
  padding-top: 10px;

  ${breakpoint.md} {
    padding: 0;
    position: absolute;
    right: 0;
  }
`;

export const StyledWrapperText = styled.div`
  width: 100%;
  max-width: 540px;

  ${breakpoint.md} {
    padding-top: 40px;
  }
`;

export const StyledWrapperAction = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const StyledButtonBlue = styled(Button)`
  &[data-variant='underline'] {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.complementary2};
    background-image: linear-gradient(
      ${({ theme }) => theme.colors.complementary2},
      ${({ theme }) => theme.colors.complementary2}
    );
    &:before {
      background: ${({ theme }) => theme.colors.complementary2};
    }
  }
`;

export const StyledIconBlue = styled(Icon)`
  color: ${({ theme }) => theme.colors.complementary2};
  width: ${({ theme }) => theme.gap.sm};
  height: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    width: 24px;
    height: 24px;
  }
`;
