import React, { ReactElement } from 'react';

import { ButtonType } from 'components/atoms/button';
import Typography from 'components/atoms/typography';
import WpImage, { WpImageType } from 'components/atoms/wp-image';

import { useScreenService } from 'hooks/use-screen-service';

import {
  StyledButtonBlue,
  StyledContainerBreadcrumb,
  StyledDots,
  StyledDotsFirstMobile,
  StyledDotsSecondMobile,
  StyledIconBlue,
  StyledPositionText,
  StyledWrapper,
  StyledWrapperAction,
  StyledWrapperContent,
  StyledWrapperImage,
  StyledWrapperText,
} from './personal-detail-head.styles';

export type PersonalDetailHeadType = {
  children?: ReactElement;
  firstName: string;
  surname: string;
  position: string;
  image: WpImageType;
  linkedinButtonLinkButton: ButtonType;
};

const PersonalDetailHead = ({
  firstName,
  position,
  surname,
  children,
  image,
  linkedinButtonLinkButton,
}: PersonalDetailHeadType) => {
  const { isMdUp } = useScreenService();
  const name = `${firstName}</br>${surname}`;
  return (
    <StyledWrapper>
      <StyledContainerBreadcrumb>{children}</StyledContainerBreadcrumb>
      <StyledWrapperContent>
        <StyledWrapperText>
          <Typography variant="title1" component="h1" html={name} color="gray100" />
          <StyledPositionText variant="title3" html={position} color="gray60" component="h3" />
          <StyledWrapperAction>
            <StyledIconBlue name="linkedin" />
            <StyledButtonBlue
              {...linkedinButtonLinkButton}
              variant="underline"
              label={linkedinButtonLinkButton.link?.ariaLabel}
            />
          </StyledWrapperAction>
        </StyledWrapperText>
        <StyledWrapperImage>
          {isMdUp ? (
            <StyledDots width={7} height={7} color="complementary3" />
          ) : (
            <>
              <StyledDotsFirstMobile width={6} height={2} color="complementary3" />
              <StyledDotsSecondMobile width={1} height={6} color="complementary3" />
            </>
          )}
          <WpImage {...image} />
        </StyledWrapperImage>
      </StyledWrapperContent>
    </StyledWrapper>
  );
};

export default PersonalDetailHead;
