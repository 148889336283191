import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import BoardSection, { BoardSectionType } from 'components/organisms/board-section';
import PersonalDetailDescription from 'components/organisms/personal-detail-description';
import PersonalDetailHead, {
  PersonalDetailHeadType,
} from 'components/organisms/personal-detail-head';
import Breadcrumb from 'components/atoms/breadcrumb';

type DataProps = {
  aboutPersonalDetailsPage: {
    template: {
      aboutBoardDetailsData: {
        otherMembersHeading: string;
        personalDetails: {
          personalDescription: string;
        } & PersonalDetailHeadType;
      };
    };
  };
  aboutBoard: {
    template: {
      aboutBoardData: {
        administrationList: BoardSectionType[];
      };
    };
  };
  aboutBoardEN: {
    template: {
      aboutBoardData: {
        administrationListEN: BoardSectionType[];
      };
    };
  };
};

const findPersonInSection = (sections, firstName, surname, position) => {
  const trimmedFirstName = firstName.trim().toLowerCase();
  const trimmedSurname = surname.trim().toLowerCase();
  const trimmedPosition = position.trim().toLowerCase();

  let personSection = sections.find((section) =>
    section.sectionList.some(
      (person) =>
        person.firstName.trim().toLowerCase() === trimmedFirstName &&
        person.surname.trim().toLowerCase() === trimmedSurname
    )
  );

  // If not found, then attempt to find by position in sections containing "Executive" in the heading.
  if (!personSection) {
    personSection = sections.find(
      (section) =>
        section.sectionHeading.toLowerCase().includes('executive') &&
        section.sectionList.some(
          (person) => person.position.trim().toLowerCase() === trimmedPosition
        )
    );
  }

  return personSection;
};

const AboutPersonalDetailsPage = ({
  pageContext,
  data: {
    aboutPersonalDetailsPage: {
      template: {
        aboutBoardDetailsData: {
          personalDetails: {
            personalDescription,
            surname,
            position,
            firstName,
            image,
            linkedinButtonLinkButton,
          },
          otherMembersHeading,
        },
      },
    },
    aboutBoard: {
      template: {
        aboutBoardData: { administrationList },
      },
    },
    aboutBoardEN: {
      template: {
        aboutBoardData: { administrationListEN },
      },
    },
  },
}: PageProps<DataProps, PageContextType>) => {
  const languageCode = pageContext.translations[0]?.language?.code || 'EN';
  const selectedAdministrationList =
    languageCode === 'EN' ? administrationList : administrationListEN;
  const personSection = findPersonInSection(
    selectedAdministrationList,
    firstName,
    surname,
    position
  );

  const administrationDetailed = personSection?.sectionList
    .filter((item) => item.firstName !== firstName && item.surname !== surname)
    .sort(() => Math.random() - 0.5)
    .slice(0, 4);

  return (
    <Layout {...{ pageContext }}>
      <PersonalDetailHead
        position={position}
        firstName={firstName}
        surname={surname}
        image={image}
        linkedinButtonLinkButton={linkedinButtonLinkButton}
      >
        <Breadcrumb {...{ pageContext }} />
      </PersonalDetailHead>
      <PersonalDetailDescription personalDescription={personalDescription} />
      {administrationDetailed ? (
        <BoardSection
          mobilePadding={`4px ${theme.gap.sm} ${theme.gap.xl}`}
          padding={`0 0 ${theme.gap.xl}`}
          sectionHeading={otherMembersHeading}
          sectionList={administrationDetailed}
          iconColor="primary50"
          key={otherMembersHeading}
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query AboutPersonalDetailsPage($id: String) {
    aboutPersonalDetailsPage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_AboutPersonalDetails {
          aboutBoardDetailsData {
            otherMembersHeading
            personalDetails {
              firstName
              personalDescription
              position
              surname
              image {
                ...WpImageFragment
              }
              linkedinButtonLinkButton {
                link {
                  ariaLabel
                  external {
                    target
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    aboutBoard: wpPage(uri: { eq: "/o-nas/zarzad-i-zespol-partnerski/" }) {
      template {
        ... on WpTemplate_AboutBoard {
          aboutBoardData {
            administrationList {
              sectionHeading
              sectionList {
                firstName
                position
                surname
                image {
                  ...WpImageFragment
                }
                linkButton {
                  label
                  link {
                    ariaLabel
                    internal: wpInternal {
                      ... on WpPage {
                        id
                        uri
                      }
                    }
                    external {
                      target
                      url
                    }
                  }
                  icon {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    aboutBoardEN: wpPage(uri: { eq: "/en/about-us/management-and-partner-team/" }) {
      template {
        ... on WpTemplate_AboutBoard {
          aboutBoardData {
            administrationListEN: administrationList {
              sectionHeading
              sectionList {
                firstName
                position
                surname
                image {
                  ...WpImageFragment
                }
                linkButton {
                  label
                  link {
                    ariaLabel
                    internal: wpInternal {
                      ... on WpPage {
                        id
                        uri
                      }
                    }
                    external {
                      target
                      url
                    }
                  }
                  icon {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutPersonalDetailsPage;
