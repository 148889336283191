import styled from 'styled-components';
import { breakpoint } from 'theme/breakpoints';

import Container from 'components/atoms/container';

export const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.colors.grayscaleGray0};
`;

export const StyledContainer = styled(Container)`
  max-width: ${({ theme }) => theme.wrapper};
  padding-block: ${({ theme }) => theme.gap.md};

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.lg};
  }
`;

export const StyledWrapperText = styled.div`
  max-width: 540px;

  p {
    padding-bottom: 20px;
  }

  ${breakpoint.md} {
    p {
      padding-bottom: 24px;
    }
  }
`;
